import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";

import Home from "./pages/Home/Home";
import MainPage from "./pages/MainPage/MainPage";
import Product from "./pages/Products/Products";
import Solutions from "./pages/Solutions/Solutions";
import Contact from "./pages/Contacts/Contacts";
import Company from "./pages/Company/Company";
import ProductMobile from "pages/Products/ProductMobile";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import TermsAndConditionsChannels from "pages/TermsOfServicesChannels/TermsOfServicesChannels";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

const apps = [
  { id: 1, name: "313 Hood videos from around th", path: "313-hood-videos" },
  { id: 2, name: "City Walks", path: "city-walks" },
  { id: 3, name: "Vision Environnement", path: "vision-environnement" },
  { id: 4, name: "Big Rig Travels", path: "big-rig-travels" },
  { id: 5, name: "RailCowGirl", path: "railcowgirl" },
  { id: 6, name: "The Flying Dutchman", path: "the-flying-dutchman" },
  { id: 7, name: "Tourister - Virtual City", path: "tourister-virtual-city" },
  { id: 8, name: "David Hoffman", path: "david-hoffman" },
  { id: 9, name: "Keezi Walks", path: "keezi-walks" },
];

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Product />} />
            <Route path="/products-mobile" element={<ProductMobile />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/company" element={<Company />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            {apps.map((app) => (
              <Route
                key={app.id}
                path={`/channels/terms-and-conditions/${app.path}`}
                element={
                  <TermsAndConditionsChannels
                    appName={app.name}
                    appUrl={app.url}
                  />
                }
              />
            ))}
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
