import React, { useRef, useEffect } from "react";
import style from "./style.module.scss";
import Carousel from "../../components/Carousel/Carousel";
import AnimatedNumber from "components/AnimatedCounter/AnimatedCounter";

import img0 from "../../assets/HomePageImages/img0.png";
import img1 from "../../assets/HomePageImages/img1.png";
import img2 from "../../assets/HomePageImages/img2.png";
import img3 from "../../assets/HomePageImages/img3.png";
import img4 from "../../assets/HomePageImages/img4.png";
import img5 from "../../assets/HomePageImages/img5.png";
import img6 from "../../assets/HomePageImages/img6.png";
import img7 from "../../assets/HomePageImages/img7.png";
import img8 from "../../assets/HomePageImages/img8.png";
//import Banner from "../../assets/HomePageImages/Banner.jpg";

// DSP images
import dspImg0 from "../../assets/DSP/img0.png";
import dspImg1 from "../../assets/DSP/img1.png";
import dspImg2 from "../../assets/DSP/img2.png";
import dspImg3 from "../../assets/DSP/img3.png";
import dspImg4 from "../../assets/DSP/img4.png";
import dspImg5 from "../../assets/DSP/img5.png";
import dspImg6 from "../../assets/DSP/img6.png";
import dspImg7 from "../../assets/DSP/img7.png";
import dspImg8 from "../../assets/DSP/img8.png";
import dspImg9 from "../../assets/DSP/img9.png";
import dspImg10 from "../../assets/DSP/img10.png";
import dspImg11 from "../../assets/DSP/img11.png";
import dspImg12 from "../../assets/DSP/img12.png";

// Performance images
import performanceImg0 from "../../assets/Performance/img13.png";
import performanceImg1 from "../../assets/Performance/img14.png";
import performanceImg2 from "../../assets/Performance/img15.png";
import performanceImg3 from "../../assets/Performance/img16.png";
import performanceImg4 from "../../assets/Performance/img17.png";
import performanceImg5 from "../../assets/Performance/img18.png";
import performanceImg6 from "../../assets/Performance/img19.png";
import performanceImg7 from "../../assets/Performance/img20.png";
import performanceImg8 from "../../assets/Performance/img21.png";
import performanceImg9 from "../../assets/Performance/img22.png";
import performanceImg10 from "../../assets/Performance/img23.png";

export default function Home() {
  const videoRef = useRef(null); // Create a reference to the video element

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Ensure the video is muted
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, []);
  return (
    <div className={style.container}>
      <div className="spacer" />
      <h1 className={style.titleNoSpacer}>Commodified media</h1>
      <h1 className={style.titleNoSpacer}>
        Precision-Driven Advertising solutions.
      </h1>
      <p className={style.titleBody}>
        Unlocking the potential of AI-driven programmatic advertising for
        incremental growth and distribution
      </p>
      <div className="spacer" />
      <a
        className="contained-button"
        href="mailto:theoffice@commodifiedimage.com"
      >
        See How We Can Help
      </a>
      <div className="spacer" />
      {/** https://firestick-david-hoffman.s3.amazonaws.com/David+Hoffman/BB+King+Was+Afraid+To+Perform+At+Sing+Sing+Prison+But+Called+It+His+Best+Performance+Ever.mp4 */}
      <video
        loop
        muted
        autoPlay
        playsInline
        className={style.banner}
        ref={videoRef}
      >
        <source
          src={
            "https://firestick-video-bucket.s3.us-east-1.amazonaws.com/313+Hood+videos/videoexample.webm"
          }
          type="video/mp4"
        ></source>
      </video>
      {/* <img className={style.banner} src={Banner} alt="BannerImage" /> */}
      <div className="spacer" />
      <h4 className={style.titleBody}>
        We use AI to deliver smarter, more targeted campaigns that drive
        measurable results for advertisers and publishers.
      </h4>
      <h4 className={style.titleBody}>
        By combining AI and data analytics with new and exciting supply pools we
        maximize reach and revenue
      </h4>
      <div className="spacer" />
      <div className="spacer" />
      <div className={style.wrapper}>
        <div className={style.counterContainer}>
          <div className={style.counter}>
            <AnimatedNumber
              targetString="35 billion ad requests"
              duration={2000}
            />
          </div>
          <div className={style.counter}>
            <AnimatedNumber targetString="5000+ publishers" duration={2000} />
          </div>

          <div className={style.counter}>
            <AnimatedNumber targetString="30 O&O Channels" duration={2000} />
          </div>
          <div className={style.counter}>
            <AnimatedNumber targetString="1 exclusive SDK" duration={1000} />
          </div>
          <div className={style.counter}>
            <AnimatedNumber targetString="100% brand safety" duration={2000} />
          </div>
        </div>
      </div>
      <div className="spacer" />
      <div className="spacer" />
      <h2>Why Choose Commodified</h2>
      <p className={style.titleBody}>
        Harness the full potential of AI across programmatic, mobile, video,
        CTV, display, and audio channels. Commodified Media elevates your
        campaigns by optimizing for both immediate performance and long-term
        brand impact, ensuring deep engagement across the entire digital
        ecosystem
      </p>
      <p className={style.titleBody}>
        We offer a distinct perspective that makes a meaningful difference to
        our clients' business and wealth.
      </p>
      <div className="spacer" />
      <div className="spacer" />
      <Carousel
        images={[
          img0,
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img8,
          dspImg0,
          dspImg1,
          dspImg2,
          dspImg3,
          dspImg4,
          dspImg5,
          dspImg6,
          dspImg7,
          dspImg8,
          dspImg9,
          dspImg10,
          dspImg11,
          dspImg12,
          performanceImg0,
          performanceImg1,
          performanceImg2,
          performanceImg3,
          performanceImg4,
          performanceImg5,
          performanceImg6,
          performanceImg7,
          performanceImg8,
          performanceImg9,
          performanceImg10,
        ]}
      />
      <div className="spacer" />
      <div className="spacer" />
    </div>
  );
}
