import React from "react";
import style from "./style.module.scss";

export default function TermsAndConditionsChannels({ appName }) {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h3 className={style.titleBody}>
        Privacy Policy
        <br />
        Last Updated: <span>10/3/2024</span>
      </h3>
      <p className={style.maxWidth}>
        Commodified Image, doing business as SpoonBill Studios ("we," "our," or
        "us") respects your privacy and is committed to protecting it through
        this Privacy Policy. This policy applies to all apps developed by
        SpoonBill Studios, including {appName}.
      </p>
      <ul className={style.maxWidth}>
        <li>
          <span className={style.mainContainerText}>
            1. Scope of This Privacy Policy
          </span>
          <br />
          This Privacy Policy applies to all Android applications developed by
          SpoonBill Studios, collectively referred to as "our apps."
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            2. Data Collection and Use
          </span>
          <br />
          Our apps do not collect, store, or process any personal or sensitive
          user data.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            3. Third-Party Services
          </span>
          <br />
          Our apps do not use third-party services that collect user data.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            4. Changes to This Policy
          </span>
          <br />
          We reserve the right to update this Privacy Policy at any time.
          Changes will be reflected on our website with an updated effective
          date.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            5. Contact Information
          </span>
          <br />
          If you have any questions about this Privacy Policy, please contact us
          at:
        </li>
      </ul>

      <div className={"spacer-xs"} />
      <p>Suite 4.3.02, Block 4, Eurotowers, Gibraltar</p>
      <a href="mailto:theoffice@commodifiedimage.com">
        theoffice@commodifiedimage.com
      </a>
      <div className="spacer" />
    </div>
  );
}
